
import * as bootstrap from 'bootstrap'
import Utils from '../../../../utils'
import DefaultController from "../../../defaultController";
import ErpWarehouseEntity from "../entity";
import WarehouseWarehouseLocationsOverview from "./show/warehouse_locations";
import WarehouseWarehouseLocationPalletsOverview from "./show/warehouse_location_pallets";
export default class ErpWarehouseShow extends DefaultController {
    async init() {
        this.entity = "erp/warehouses"
        await super.init();
        this.childs = {
            warehouse_locations: new WarehouseWarehouseLocationsOverview(this),
            warehouse_location_pallets: new WarehouseWarehouseLocationPalletsOverview(this),
        }
    }

    protected getEntityData(elem: any) {
        return ErpWarehouseEntity.getEntityData(elem)
    }
    bindListeners() {
        (document.querySelector("#editErpWarehouseForm") as HTMLFormElement).addEventListener("submit", async (e) => {
            e.preventDefault();
            const elem = document.querySelector("#editErpWarehouseForm") as HTMLFormElement;
            const valid = elem.checkValidity();
            if (valid) {
                const r = await Utils.entity.upsert(this.getEntityData(elem), this.entity)
                if (r.status === 200) {
                    await this.getEntity()
                    const bsElem = bootstrap.Modal.getInstance((document.querySelector("#editWarehouse") as HTMLElement))
                    if (bsElem) {
                        bsElem.hide();
                    }
                    this.toastr.success(`${Utils.translate('warehouse.name')} ${Utils.translate('generic.saved')}`, `${Utils.translate('generic.success')}`)
                }
            }
        });
    }
}